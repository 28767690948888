import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createDeviceDetector } from 'next-vue-device-detector'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"

export const device = createDeviceDetector();

router.beforeEach((to, next) => {
    document.title = `${ process.env.VUE_APP_TITLE } - ${to.meta.title}`
})

createApp(App).use(router).use(device).mount('#app')
