<template>
  <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');
@font-face {
    font-family: FesterBold;
    src: url("~@/assets/FesterBold.otf") format("opentype");
}
* {
    font-family: 'Montserrat', sans-serif !important;
}
button {
  &:focus {
    box-shadow: none !important;
  }
}
</style>
